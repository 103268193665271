<script lang="ts" setup>
import { useToast } from '~/composables/toast/UseToast';
import BaseToast from '~/components/base/toasts/Toast.vue';
const { removeToast, toasts } = useToast();
</script>

<template>
    <BaseToast
        v-for="toast in toasts"
        :key="toast.id"
        :icon="toast.icon"
        :timeout="toast.timeout"
        :variant="toast.variant"
        @close="removeToast(toast.id)"
    >
        {{ toast.message }}
    </BaseToast>
</template>
