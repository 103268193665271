import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as change_45passwordlnF89pKfG4Meta } from "/app/pages/account/change-password.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as profilelwi6rEuniiMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as settingsD93HPwacmMMeta } from "/app/pages/account/settings.vue?macro=true";
import { default as createzx9hhj8PhOMeta } from "/app/pages/admin/faqs/categories/create.vue?macro=true";
import { default as indexSyIWD0totSMeta } from "/app/pages/admin/faqs/categories/index.vue?macro=true";
import { default as indexOLeLk7W3zpMeta } from "/app/pages/apps/index.vue?macro=true";
import { default as _91id_93GfbQespuG8Meta } from "/app/pages/articles/[id].vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as indexNvynyXSzdBMeta } from "/app/pages/brandguide/index.vue?macro=true";
import { default as indexgOCBgJx4RxMeta } from "/app/pages/chat/index.vue?macro=true";
import { default as _91id_93K4vYeLZ7G4Meta } from "/app/pages/colleagues/[id].vue?macro=true";
import { default as indexGEXVmMkvY7Meta } from "/app/pages/colleagues/index.vue?macro=true";
import { default as _91id_932G5SX2LjFTMeta } from "/app/pages/events/[id].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as _91id_938EEu8awO2YMeta } from "/app/pages/faqs/[id].vue?macro=true";
import { default as _91id_93ICKrP8KqbbMeta } from "/app/pages/faqs/categories/[id].vue?macro=true";
import { default as index0eabsiohfOMeta } from "/app/pages/faqs/index.vue?macro=true";
import { default as index83Vlw4pEqKMeta } from "/app/pages/feed/index.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as createYzTx0fhvxOMeta } from "/app/pages/incidents/create.vue?macro=true";
import { default as indexo9H876Zfz3Meta } from "/app/pages/incidents/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indext59oQyDmXrMeta } from "/app/pages/messages/index.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as index6fQQwAuTo6Meta } from "/app/pages/polls/index.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as createIvomSKIW8dMeta } from "/app/pages/suggestions/create.vue?macro=true";
import { default as index4A7IZrQM3oMeta } from "/app/pages/suggestions/index.vue?macro=true";
import { default as indexfpe18gV1u8Meta } from "/app/pages/surveys/index.vue?macro=true";
import { default as indexwMLsOIPseBMeta } from "/app/pages/wanda/index.vue?macro=true";
import { default as workspace_45signinmJbz14kF0yMeta } from "/app/pages/workspace-signin.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "account-change-password",
    path: "/account/change-password",
    component: () => import("/app/pages/account/change-password.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: "admin-faqs-categories-create",
    path: "/admin/faqs/categories/create",
    component: () => import("/app/pages/admin/faqs/categories/create.vue")
  },
  {
    name: "admin-faqs-categories",
    path: "/admin/faqs/categories",
    component: () => import("/app/pages/admin/faqs/categories/index.vue")
  },
  {
    name: "apps",
    path: "/apps",
    component: () => import("/app/pages/apps/index.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    component: () => import("/app/pages/articles/[id].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/app/pages/articles/index.vue")
  },
  {
    name: "brandguide",
    path: "/brandguide",
    component: () => import("/app/pages/brandguide/index.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/app/pages/chat/index.vue")
  },
  {
    name: "colleagues-id",
    path: "/colleagues/:id()",
    component: () => import("/app/pages/colleagues/[id].vue")
  },
  {
    name: "colleagues",
    path: "/colleagues",
    component: () => import("/app/pages/colleagues/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    component: () => import("/app/pages/events/[id].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "faqs-id",
    path: "/faqs/:id()",
    component: () => import("/app/pages/faqs/[id].vue")
  },
  {
    name: "faqs-categories-id",
    path: "/faqs/categories/:id()",
    component: () => import("/app/pages/faqs/categories/[id].vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/app/pages/faqs/index.vue")
  },
  {
    name: "feed",
    path: "/feed",
    component: () => import("/app/pages/feed/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "incidents-create",
    path: "/incidents/create",
    component: () => import("/app/pages/incidents/create.vue")
  },
  {
    name: "incidents",
    path: "/incidents",
    component: () => import("/app/pages/incidents/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "messages",
    path: "/messages",
    component: () => import("/app/pages/messages/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/app/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/pages/news/index.vue")
  },
  {
    name: "polls",
    path: "/polls",
    component: () => import("/app/pages/polls/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "suggestions-create",
    path: "/suggestions/create",
    component: () => import("/app/pages/suggestions/create.vue")
  },
  {
    name: "suggestions",
    path: "/suggestions",
    component: () => import("/app/pages/suggestions/index.vue")
  },
  {
    name: "surveys",
    path: "/surveys",
    component: () => import("/app/pages/surveys/index.vue")
  },
  {
    name: "wanda",
    path: "/wanda",
    component: () => import("/app/pages/wanda/index.vue")
  },
  {
    name: "workspace-signin",
    path: "/workspace-signin",
    meta: workspace_45signinmJbz14kF0yMeta || {},
    component: () => import("/app/pages/workspace-signin.vue")
  }
]