import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45admin_45layout_45global from "/app/middleware/SetAdminLayout.global.ts";
import auth_45global from "/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  set_45admin_45layout_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}