import { useCookie } from 'nuxt/app';

interface CookieOptions {
    expires?: number;
    path?: string;
    sameSite?: string;
}

export const setCookie = (key: string, value: string, options: CookieOptions = <CookieOptions>{}) => {
    const SECONDS_IN_A_DAY = 60 * 60 * 24;
    const defaultOptions = {
        expires: 7,
        path: '/',
        sameSite: 'lax',
    };

    const finalOptions = {
        ...defaultOptions,
        ...options,
    };

    if (options.expires) {
        finalOptions.expires = options.expires / SECONDS_IN_A_DAY;
    }

    const cookie = useCookie(key, {
        maxAge: finalOptions.expires * SECONDS_IN_A_DAY,
        path: finalOptions.path,
        sameSite: finalOptions.sameSite,
    });

    cookie.value = value;
};
