import { generalInterceptor } from '~/composables/api/interceptors/General';
import { type FetchContext } from 'ofetch';
import {
    type InterceptorRequestErrorHandlerCallback,
    type InterceptorResponseHandlerCallback,
} from '~/types/InterceptorHandlerCallback';
import { useAuthStore } from '~/modules/auth/store/store';

export const apiAuthFetchInterceptor = () => {
    const generalInterceptorInstance = generalInterceptor();
    const authStore = useAuthStore();

    return {
        async onRequest(context: FetchContext): Promise<void> {
            await generalInterceptorInstance.onRequest(context);
        },

        async onRequestError(context: InterceptorRequestErrorHandlerCallback): Promise<void> {
            await generalInterceptorInstance.onRequestError(context);
        },

        async onResponse(context: InterceptorResponseHandlerCallback): Promise<void> {
            await generalInterceptorInstance.onResponse(context);
        },

        // eslint-disable-next-line require-await
        async onResponseError(context: InterceptorResponseHandlerCallback): Promise<void> {
            if (Number(context?.response?.status) === 401 && authStore.hasRefreshToken()) {
                authStore.logoutUser();
            }
        },
    };

};
